const ACL1 = [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "User Registration List",
        to: "/users",
        icon: "cil-people",
      },
      {
        _name: "CSidebarNavItem",
        name: "Service Request List",
        to: "/services",
        icon: "cil-newspaper",
      },
      {
        _name: "CSidebarNavItem",
        name: "Machine Registration List",
        to: "/machine",
        icon: "cil-description",
      },
      {
        _name: "CSidebarNavItem",
        name: "Warranty Request List",
        to: "/warranty",
        icon: "cil-warning",
      },

      {
        _name: "CSidebarNavItem",
        name: "Customer Registration List",
        to: "/customer",
        icon: "cil-building",
      },
      {
        _name: "CSidebarNavItem",
        name: "Shipment Data",
        to: "/shipmentdata",
        icon: "cil-truck",
      },
      {
        _name: "CSidebarNavItem",
        name: "Service Zonal Manager",
        to: "/managers",
        icon: "cil-user",
      },
      {
        _name: "CSidebarNavItem",
        name: "Guide and Help",
        to: "/help",
        icon: "cil-info",
      },

      {
        _name: "CSidebarNavItem",
        name: "Notifications",
        to: "/notifications",
        icon: "cil-bell",
      },

      {
        _name: "CSidebarNavItem",
        name: "Settings",
        to: "/settings",
        icon: "cil-settings",
      },
    ],
  },
];

const ACL2 = [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "User Registration List",
        to: "/users",
        icon: "cil-people",
      },
      {
        _name: "CSidebarNavItem",
        name: "Service Request List",
        to: "/services",
        icon: "cil-newspaper",
      },
      {
        _name: "CSidebarNavItem",
        name: "Machine Registration List",
        to: "/machine",
        icon: "cil-description",
      },

      {
        _name: "CSidebarNavItem",
        name: "Notifications",
        to: "/notifications",
        icon: "cil-bell",
      },
    ],
  },
];

export default { ACL1, ACL2 };
